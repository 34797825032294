import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const PlanAndPricingPolicy = () => {
  return (
    <Layout>
      <Seo title={`Plan and pricing policy`} />
      <div className="public_page">
        <h4>
          <strong>PLANS AND PRICING POLICY</strong>
        </h4>
        <p>
          This document is a part of our Terms of Service, by clicking on{" "}
          <strong>&ldquo;I Accept</strong>&rdquo; button while accepting the
          Terms of Service as part of the Sign-Up form, its deemed that you have
          also accepted this Plans and Pricing Policy.
        </p>
        <h4>
          <strong>PLANS: </strong>
        </h4>
        <p>
          Currently we are offering two plans (&ldquo;Plan&rdquo;&rdquo;) each
          with separate payment terms and features, as detailed below:
        </p>
        <ol style={{ listStyle: "auto" }}>
          <li>
            <strong>Pay As You Go (&ldquo;PAYG&rdquo;) Plan: </strong>
          </li>
        </ol>
        <table>
          <tbody>
            <tr>
              <td width="186">
                <p>Free Trial</p>
              </td>
              <td width="415">
                <ul>
                  <li>
                    AlignerBase is offering 1 Free Trial order to all new
                    clients for a limited time period.
                  </li>
                  <li>
                    All new customers will by default be eligible to avail the
                    Free Trial order.
                  </li>
                  <li>
                    The below pricing will be applicable after the Trial order
                    has been completed/consumed.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td width="186">
                <p>Pricing</p>
              </td>
              <td width="415">
                <ul>
                  <li>Under this plan the client will be charged:</li>
                  <ul>
                    <li>
                      USD $59 for each design/order, that treats both arches
                      (Upper and Lower) or a single arch, and not limited by the number of
                      aligner steps required per case.
                    </li>
                   
                  </ul>
                  <li>
                    The above pricing is subject to change, and we may change it
                    by giving you a one (1) month prior notice.
                  </li>
                  <li>
                    The payment of any order will be charged in advance to you
                    being able to download the final file. Monthly billing is
                    offered to Enterprise clients only. Please view the
                    &ldquo;Invoices&rdquo; section for details.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td width="186">
                <p>One-Time Fee</p>
              </td>
              <td width="415">
                <ul>
                  <li>
                    There are no initial or recurring joining fees. You can sign
                    up and begin submitting orders with no charge.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td width="186">
                <p>Revision Fee</p>
              </td>
              <td width="415">
                <ul>
                  <li>
                    There is no Revision fee, where &lsquo;Revision&rsquo;
                    implies a request to adjust the plan prior to approval,
                    during the treatment planning phase and before the treatment
                    begins.
                  </li>
                  <li>
                    Client may request treatment Revision as many times as
                    required to finalize the treatment plan, within reasonable
                    limits.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td width="186">
                <p>Refinement Fee</p>
              </td>
              <td width="415">
                <ul>
                  <li>
                    PAYG plan includes one (1) free Refinement, where
                    &lsquo;Refinement&rsquo; implies a request for a change to
                    the treatment plan (mid-course correction) after the plan
                    has been approved/finalized and patient has begun aligner
                    treatment.
                  </li>
                  <li>Additional refinements will be charged at USD $35.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <ol start="2" style={{ listStyle: "auto" }}>
          <li>
            <strong>Enterprise Plan: </strong>
          </li>
        </ol>
        <table>
          <tbody>
            <tr>
              <td width="186">
                <p>Pricing</p>
              </td>
              <td width="415">
                <ul>
                  <li>
                    Under this plan the client will be charged tiered pricing
                    based on the following 3 tiers:
                  </li>
                  <ul>
                    <li>
                      <strong>Tier 1:</strong> Client will pay USD $54 for each
                      design (for both arches or a single arch) - until the
                      limit of maximum 200 designs/orders.
                    </li>
                    <li>
                      <strong>Tier 2:</strong> Client will pay USD $49 for each
                      design (for both arches or a single arch) - after
                      exceeding the Tier 1 limit of 200 designs/orders and until
                      the limit of maximum 500 designs/orders.
                    </li>
                    <li>
                      <strong>Tier 3:</strong>&nbsp; Client will pay USD $44 for
                      each design (for both arches or a single arch)
                      - upon exceeding the Tier 2 limit of 500
                      designs/orders to onwards.
                    </li>
                  </ul>
                  <li>
                    This pricing is not limited by the number of aligner steps
                    required per case.
                  </li>
                  <li>
                    This price is subject to change, and we may change this
                    price by giving you a one (1) month prior notice.
                  </li>
                  <li>
                    Enterprise clients will be invoiced monthly. Please view the
                    &ldquo;Invoices&rdquo; section for details.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td width="186">
                <p>One-Time Fee</p>
              </td>
              <td width="415">
                <ul>
                  <li>
                    A One-Time Fee of USD $599 for Enterprise customers is
                    billed at the beginning only and credited to Enterprise
                    customer accounts immediately; this amount will be fully
                    adjustable towards invoices and additional payment will only
                    be required once this amount has been fully consumed.
                  </li>
                  <li>
                    There are no recurring fees after the above One-Time Fee.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td width="186">
                <p>Revision Fee</p>
              </td>
              <td width="415">
                <ul>
                  <li>
                    There is no Revision fee, where &lsquo;Revision&rsquo;
                    implies a request to adjust the plan prior to approval,
                    during the treatment planning phase and before the treatment
                    begins.
                  </li>
                  <li>
                    Client may request treatment Revision as many times as
                    required to finalize the treatment plan, within reasonable
                    limits.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td width="186">
                <p>Refinement Fee</p>
              </td>
              <td width="415">
                <ul>
                  <li>
                    Enterprise plan includes one (1) free Refinement, where
                    &lsquo;Refinement&rsquo; implies a request for a change to
                    the treatment plan (mid-course correction) after the plan
                    has been approved/finalized and patient has begun aligner
                    treatment.
                  </li>
                  <li>Additional refinements will be charged at USD $35.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <h4>
          <strong>PLATFORM ACCESS: </strong>
        </h4>
        <p>
          PAYG plan clients will have a single-user access to the AlignerBase
          platform and will not be able to add their business and additional
          users.
        </p>
        <p>
          Enterprise plan clients will have multi-user access to the AlignerBase
          platform and will be able to add their business details and additional
          Users. &nbsp;
        </p>
        <h4>
          <strong>SUPPORT: </strong>
        </h4>
        <p>
          PAYG plan clients will have access to support via email and chat, in
          any area related to the usage of our Services as defined under the
          Terms of Service.&nbsp;
        </p>
        <p>
          Enterprise plan clients will have access to support via email and
          chat, in any area related to the usage of our Services as defined
          under the Terms of Service; in addition, live phone support will be
          made available to Enterprise customers the month after their monthly
          case load exceeds 200 &ndash; for example, if a customer&rsquo;s case
          load is 201 in June, Phone Support will be made available July
          onwards.
        </p>
        <h4>
          <strong>ACCOUNT MANAGER: </strong>
        </h4>
        <p>
          Account Managers will provide industrial standard support to the
          Client regarding usage of our Services as defined under the Terms of
          Service.
        </p>
        <p>PAYG clients will not be assigned a dedicated Account Manager.</p>
        <p>
          Enterprise Client will be assigned a dedicated Account Manager after
          their monthly case load exceeds 200 &ndash; for example, if a
          customer's case load is 201 in June, an Account Manager will be
          assigned July onwards.
        </p>
        <h4>
          <strong>DELIVERY: </strong>
        </h4>
        <p>
          Provided all necessary records and instructions have been provided,
          Plans shall be made available for review within up to seven (7)
          business days from order submission.
        </p>
        <h4>
          <strong>CANCELLATIONS: </strong>
        </h4>
        <p>
          Clients may cancel an order prior to finalization through the orders
          page of the AlignerBase platform. An order that is cancelled while
          still at the &lsquo;Draft&rsquo; stage will have no charge; however,
          if an order is cancelled after it has been accepted and designing has
          begun, a cancellation fee of USD $35 will be charged.
        </p>
        <p>
          In addition to the above, you acknowledge and agree that any plans
          that have not been &lsquo;Approved&rsquo; by the client within one (1)
          month from posting date shall be deemed as cancelled and you will be
          charged USD $35 for such canceled orders.
        </p>
        <h4>
          <strong>PRICING:</strong>
        </h4>
        <p>
          The price of the service will be visible in USD Our prices are subject
          to change, and we may change this price by giving you a one (1) month
          prior notice. The price will be made clear during the order process.
          You agree to pay the price that is stated at the time of your order,
          as well as any applicable taxes. You also agree to the billing
          frequency stated at the time of your order. You are responsible for
          paying all VATs, and Taxes (if any) which are excluded from any price
          outlined in the appropriate payment form. In no way we have any
          liability for payment of VATs or Taxes.
        </p>
        <h4>
          <strong>PAYMENTS</strong>: &nbsp;
        </h4>
        <p>
          All payments will be handled through a third party secure payment
          gateway ethrough our website or otherwise. All payments will be
          handled by Stripe, through their secure payment gateway. If you use
          the Stripe payment gateway to pay for an order or plan, you represent
          and warrant that you are authorized to use Stripe. The payment amount
          may include taxes, VAT and/or any other amounts which will be
          described under these terms of service or on our website. Any attempt
          to defraud us through the use of Stripe payment methods or any failure
          by you to honor charges or requests for payment will result in
          immediate termination of your account and civil and/or criminal
          prosecution. In the case of suspected or fraudulent payment, including
          the use of stolen credentials, by anyone, or any other fraudulent
          activity, we reserve the right to block the user account. If your
          payment method unable to verify or found invalid, we may suspend or
          cancel your plan/order or restrict your access to our website and its
          services automatically. We will reserve the right to refuse any
          payments from our user in our sole discretion.
        </p>
        <h4>
          <strong>INVOICES: </strong>
        </h4>
        <p>
          For any order(s), cancellation(s) and refinement(s) you will be
          invoiced instantly for PAYG clients, and you will be invoiced monthly
          for Enterprise clients. All invoices should be paid within a maximum
          of seven (7) working days from the date of issuance of such
          invoice(s). In case of non-payment, an email notification will be sent
          to the client and client account will be flagged in our system as a
          non-payee and all access to previous and new case files and any case
          related material will be locked automatically.
        </p>
        <h4>
          <strong>REFUNDS: </strong>
        </h4>
        <p>
          Signing up and paying to join any of our plans is non-refundable,
          except as otherwise may be required by applicable law.
        </p>
        <h4>
          <strong>TERMINATION OR SUSPENSION</strong>:
        </h4>
        <p>
          We also reserve the right to suspend or terminate your plan for any
          reason, with or without notice and without further obligation. In the
          event that your account or access to our platform or its services are
          suspended or terminated for violation of this terms of services, there
          shall be no refund offered.
        </p>
        <h4>
          <strong>DISCLAIMER AND WARRANTY:</strong>
        </h4>
        <p>
          We make no warranty that (i) our platform will meet your requirements;
          (ii) the results that may be obtained from the use of our platform
          will be accurate or reliable; and (iv) the quality of any products,
          website, information, or other material purchased or obtained by you
          through our platform will meet your expectations. We expressly
          disclaim any representations or warranties that your use of our
          platform will satisfy any statutory or regulatory obligations, or will
          assist with, guarantee, or otherwise ensure compliance with any
          applicable laws or regulations, including but not limited to The
          Health Insurance Portability And Accountability Act Of 1996
          (&ldquo;HIPAA&rdquo;) and as modified and amended, or other federal or
          state statutes or regulations. We do not guarantee, implicitly or
          explicitly, any results. No advice or information, whether oral or
          written, obtained by you from or through our platform shall create any
          warranty.
        </p>
        <p>
          Except for the above express limited warranty, we make, and you
          receive no warranties on the plans, express, implied, statutory, or in
          any other provision of this policy or communication with the Customer,
          and we specifically disclaim any implied warranties of merchantability
          or fitness for a purpose. We do not warrant that the operation of the
          appliances designed with the help of the plans will be uninterrupted
          or error free.
        </p>
        <p>
          If you have any question about this Pricing, Payment, and Refund
          Policy or simply want more information, contact our Compliance team
          at:
        </p>
        <div>
          <strong>Alignerbase LLC,</strong>
        </div>
        <div>
          <strong>Address:</strong>&nbsp;1007 N ORANGE ST STE 1357
        </div>
        <div>4TH FLOOR</div>
        <div>WILMINGTON</div>
        <div>DE 19801</div>
        <div>
          <strong>Email:</strong>{" "}
          <a href="mailto:info@alignerbase.com">info@alignerbase.com</a>
        </div>
      </div>
    </Layout>
  )
}

export default PlanAndPricingPolicy
